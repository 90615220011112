<template>
  <b-card v-if="additionalOptions" no-body class="card-browser-states">
    <b-card-header>
      <div>
        <h3>Inventaire - Options supplémentaires</h3>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="mt-2">
      <hr />
      <b-row class="mb-1 mt-4">
        <b-col cols="12">
          <h4 class="text-primary">Détails par option</h4>
        </b-col>
      </b-row>

      <div v-for="section in additionalOptions" :key="section.id">
        <div v-for="question in section.questions" :key="question.id">
          <div class="earnings-card">
            <b-row>
              <b-col cols="12">
                <div class="float-left">
                  <h5>
                    {{ question.title }}
                  </h5>
                </div>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  class="float-right"
                  hidden
                >
                  <feather-icon
                    icon="EditIcon"
                    size="14"
                    class="cursor-pointer"
                  />
                  Ajuster
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-for="choice in question.choices"
              :key="choice.id"
              class="mt-2"
            >
              <b-col cols="6">
                <b-row>
                  <b-col cols="12">
                    <h6 class="">
                      {{ choice.title }}
                    </h6>
                  </b-col>
                  <b-col cols="6">
                    <div class="font-small-2">Disponibles</div>
                    <h4 class="mb-1">
                      {{ choice.remaining_inventory }}
                    </h4>
                  </b-col>
                  <b-col cols="6">
                    <div class="font-small-2">Initial</div>
                    <h4 class="mb-1">
                      {{ choice.inventory_count }}
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <InventoryGraph
                  :data-series="[
                    choice.inventory_count - choice.remaining_inventory,
                    choice.remaining_inventory,
                  ]"
                />
              </b-col>
            </b-row>
          </div>
          <hr class="my-2" />
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BImg,
  BCol,
  BRow,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import InventoryGraph from "./InventoryGraph.vue";

import groups from "@mocks/mockGroups";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BCol,
    BRow,
    BMedia,
    BButton,
    BImg,
    VueApexCharts,
    BAvatar,
    InventoryGraph,
  },
  props: ["accommodationOptions", "currentGroup", "additionalOptions"],
  data() {
    return {
      accommodations: this.accommodationOptions,
    };
  },
  computed: {
    totalRooms() {
      let totalRooms = 0;

      if (this.accommodations && Array.isArray(this.accommodations)) {
        this.accommodations.forEach((option) => {
          totalRooms =
            totalRooms +
            option.rooms.reduce((total, obj) => obj.inventory + total, 0);
        });
      }

      return totalRooms;
    },
    totalRemainingRooms() {
      let totalRemainingRooms = 0;

      if (this.accommodations && Array.isArray(this.accommodations)) {
        this.accommodations.forEach((option) => {
          totalRemainingRooms =
            totalRemainingRooms +
            option.rooms.reduce(
              (total, obj) =>
                obj.room_remaining_inventory.remaining_places + total,
              0
            );
        });
      }

      return totalRemainingRooms;
    },
  },
  watch: {
    accommodationOptions: {
      handler(newAccommodations) {
        this.accommodations = newAccommodations;
      },
      deep: true,
    },
  },
};
</script>
