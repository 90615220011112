<template>
  <div>
    <b-card>
      <div class="mt-1">
        <b-row class="match-height mt-2">
          <b-col cols="6">
            <TransportOptionsInventory
              :current-group="currentGroup"
              :transport-options="transportOptions"
            />
          </b-col>
          <b-col cols="6">
            <AccommodationOptionsInventory
              :current-group="currentGroup"
              :accommodation-options="accommodationOptions"
            />
          </b-col>
          <b-col v-if="showAdditionalOptions" cols="6">
            <AdditionalOptionsInventory
              :current-group="currentGroup"
              :additional-options="additionalOptions"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
} from "bootstrap-vue";
import TransportOptionsInventory from "./TransportOptionsInventory.vue";
import AccommodationOptionsInventory from "./AccommodationOptionsInventory.vue";
import AdditionalOptionsInventory from "./AdditionalOptionsInventory.vue";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    TransportOptionsInventory,
    AccommodationOptionsInventory,
    AdditionalOptionsInventory,
  },
  props: [
    "transportOptions",
    "accommodationOptions",
    "currentGroup",
    "additionalOptions",
  ],
  data() {
    return {
      currentReservation: {},
      currentPackages: [],
      tabIndex: 0,
      activateTabs: true,
    };
  },
  computed: {
    showAdditionalOptions() {
      return this.$store.state["app-auth"]?.orgSettings
        ?.additional_options_enable;
    },
  },
};
</script>

<style></style>
